<template>
  <div class="brain-popover" v-if="showPopover">
    <div class="popover-mask"></div>
    <div class="popover-content">
      <div class="popover-head">
        <span class="popover-title">{{ popoverTitle }}</span>
      </div>
      <div class="popover-body doctor-popover">
        <el-form :model="doctor" :rules="rules" ref="modelForm" label-width="140px" size="small">
          <el-form-item :label="$t('student.doctor') + '：'" prop="doctor_name">
            <el-input v-model="doctor.doctor_name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('doctor.department') + '：'" prop="department_id">
            <el-select v-model="doctor.department_id" placeholder=" ">
              <el-option
                v-for="item in departmentList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('doctor.email') + '：'" prop="email">
            <el-input v-model="doctor.email"></el-input>
          </el-form-item>
          <el-form-item :label="$t('doctor.init_password') + '：'" prop="password">
            <el-input type="password" v-model="doctor.password"></el-input>
          </el-form-item>
          <el-form-item :label="$t('doctor.username') + '：'" prop="username">
            <el-input v-model="doctor.username"></el-input>
          </el-form-item>
          <el-form-item :label="$t('department.title') + '：'">
            <el-input v-model="doctor.title"></el-input>
          </el-form-item>
          <el-form-item :label="$t('doctor.tel_number') + '：'">
            <el-input v-model="doctor.telephone"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="popover-footer text-right">
        <button
          type="button"
          class="btn btn-grey btn-sm ml10"
          @click.stop="closePopover"
        >
          {{ $t("cancel") }}
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm ml10"
          @click.stop="submitDoctor('modelForm')"
        >
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    doctor: {
      type: Object,
      default: function() {
        return {};
      }
    },
    departmentList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    showPopover: {
      type: Boolean,
      default: false
    },
    popoverTitle: {
      type: String,
      default: ""
    },
    isAdd: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    rules() {
      return {
        doctor_name: [
          { required: true, message: this.$t("validate.doctor"), trigger: 'change' }
        ],
        department_id: [
          { required: true, message: this.$t("validate.department"), trigger: 'change' }
        ],
        email: [
          { required: true, message: this.$t("validate.email"), trigger: 'change' }
        ],
        password: [
          { required: this.isAdd, message: this.$t("validate.init_password"), trigger: 'change' }
        ],
        username: [
          { required: true, message: this.$t("validate.username"), trigger: 'change' }
        ],
      }
    }
  },
  methods: {
    submitDoctor(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("submitDoctor", this.doctor);
        } else {
          return false;
        }
      });
    },
    closePopover() {
      this.$emit("closePopover");
    }
  }
};
</script>

<style lang="less">
.doctor-popover{
  .el-select{
    width: 100%;
  }
  label{
    margin-bottom: 0;
  }
} 
</style>
